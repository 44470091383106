






































import { Vue, Component } from 'vue-property-decorator';

@Component({
  name: 'UnsupportedBrowser',
})
export default class UnsupportedBrowser extends Vue {
  private readonly supportedBrowsers = [
    { icon: 'mdi-google-chrome', name: 'Google Chrome' },
    { icon: 'mdi-firefox', name: 'Mozilla Firefox' },
    { icon: 'mdi-microsoft-edge', name: 'Microsoft Edge' },
    { icon: 'mdi-apple-safari', name: 'Safari' },
    { icon: 'mdi-opera', name: 'Opera' },
  ];
}

